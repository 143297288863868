//
// _email.scss
//

.email-panel {
    position: relative;
    @media (min-width: 992px) {
        min-width: 80px;
        max-width: 80px;

        &.show {
            min-width: 120px;
            max-width: 120px;
        }
    }

    .panel-list {
        a {
            flex-direction: column;
            margin-bottom: 6px;

            span {
                display: inline-block;
                margin-top: 1px;
            }
        }
    }

    &.show {
        .panel-list {
            a {
                flex-direction: row;

                span {
                    margin-left: 8px;
                }
            }
        }
    }
}

// chat left sidebar
.email-menu-sidebar {
    height: calc(100vh - 137px);
    position: relative;
    transition: all 0.2s;

    @media (min-width: 992px) {
        min-width: 250px;
        max-width: 250px;
    }

    @media (max-width: 991.98px) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        width: 200px;
        max-width: 100%;
        z-index: 1003;
        transform: translateX(-100%);
        visibility: hidden;
        height: 100vh;

        &.menubar-show {
            visibility: visible;
            transform: none;
        }
    }

    .email-menu-sidebar-scroll {
        height: calc(100vh - 238px);

        @media (max-width: 991.98px) {
            height: calc(100vh - 80px);
        }
    }
}

:is(.mail-list, .panel-list) {
    a {
        display: flex;
        align-items: center;
        padding: 5px 0;
    }
}

.email-chat-list {
    a {
        padding: 6px 24px;
    }
}

.email-content {
    width: 100%;
    transition: all 0.2s;
}

.unreadConversations-alert {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.email-detail-content {
    position: relative;
    top: 0;
    bottom: 0;
    left: -2px;
    width: 0;
    transition: all 0.2s;
    display: none;
}

.email-detail-show {
    .email-detail-content {
        transform: none;
        display: block;
    }

    .email-content {
        margin-right: 0;
    }
}

.email-detail-content-scroll {
    height: calc(100vh - 395px);

    @media (max-width: 1349.98px) {
        height: calc(100vh - 257px);
    }
}

.message-list {
    display: block;
    padding-left: 0;
    margin: 0;

    li {
        position: relative;
        display: block;
        height: 50px;
        line-height: 50px;
        cursor: default;
        transition-duration: .3s;
        clear: both;

        &:hover {
            transition-duration: .05s;
        }

        .col-mail {
            float: left;
            position: relative;
        }

        .col-mail-1 {
            width: 280px;

            .star-toggle,
            .checkbox-wrapper-mail,
            .dot {
                display: block;
                float: left;
            }

            .dot {
                border: 4px solid transparent;
                border-radius: 100px;
                margin: 22px 26px 0;
                height: 0;
                width: 0;
                line-height: 0;
                font-size: 0;
            }

            .checkbox-wrapper-mail {
                margin: 15px 0 0 20px;
                line-height: normal;
            }

            .star-toggle {
                margin-top: 18px;
                margin-left: 5px;
            }

            .title {
                position: absolute;
                top: 0;
                left: 95px;
                right: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-bottom: 0;

                @media (max-width: 575.98px) {
                    left: 95px;
                }
            }
        }

        .col-mail-2 {
            position: absolute;
            top: 0;
            left: 280px;
            right: 0;
            bottom: 0;

            .subject,
            .date {
                position: absolute;
                top: 0;
            }

            .subject {
                left: 0;
                right: 110px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .date {
                right: 0;
                width: 100px;
                padding-left: 20px;
            }
        }
    }
}

#unreadConversations,
#email-topbar-actions {
    display: none;
}

#elmLoader {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
}

.email-compose-input {
    padding-right: 80px;
}

@media (max-width: 1349.98px) {
    .email-detail-show {
        .email-detail-content {
            transform: none;
            visibility: visible;
        }
    }
}

.chat-list.right  {
    display: flex;
    justify-content: flex-end;
    .conversation-name {
        justify-content: flex-end;
    }
    .message-box-drop {
        order: 1;
    }
    .ctext-wrap-content {
        order: 2;
        border-radius: 12px 12px 0px 12px;
    }
}

.ctext-wrap {
    gap: 10px;
}


@media (max-width: 575.98px) {
    .message-list li .col-mail-1 {
        width: 180px;
    }
}

.email-editor {
    .ck-editor__editable_inline {
        min-height: 200px !important;
    }
}

.email-chat-detail {
    width: 350px;
    position: fixed;
    max-width: 100%;
    z-index: 9;
    display: none;

    @media (max-width: 515.98px) {
        left: 16px;
        right: 16px;
    }
}

.sc-fnhmGq, .eJrUfb {
    align-items: center;
}