.invitation-copy {
    padding: 2px; 
    height: 16px; 
    line-height: 8px; 
    font-size: 10px; 
    background: #75bcff; 
    color: white; 
    border: none; 
    border-radius: 4px; 
    cursor: pointer;
}