
$font-family-base: 'Inter', sans-serif;

$font-weights: (
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700
);


$spacing-values: (
    'auto': auto,
    '0': 0,
    '1': 0.25rem,
    '2': 0.5rem,
    '3': 1rem,
    '4': 1.5rem,
    '5': 3rem
);